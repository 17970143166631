<template>
  <div v-if="categories.length" class="flex flex-col" data-cy="category-filter">
    <workflow-category-filter-section
      :name="$t('COMMON.ALL')"
      :active="!selectedCategoryIds.length"
      @click="selectAll"
    />
    <workflow-category-filter-section
      v-for="category in categories"
      :key="category.id"
      :name="category.name"
      :active="isSelectedCategory(category.id)"
      @click="updateCategories(category.id)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, type PropType } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import type { WorkflowTemplateCategoriesDefinition } from "@/models/workflows";
import WorkflowCategoryFilterSection from "@/components/presets/presetModal/WorkflowCategoryFilterSection.vue";

const emit = defineEmits<{
  (eventName: "update:modelValue", id: number[]): void;
}>();

defineProps({
  modelValue: {
    type: Array as PropType<number[]>,
    required: true
  }
});

const { t } = useI18n();
const { getters } = useStore();

const selectedCategoryIds = ref<number[]>([]);

const workflowCategoriesInfo = computed<WorkflowTemplateCategoriesDefinition[]>(
  () => getters["options/workflowCategoriesInfo"]
);

const categories = computed(() => {
  return workflowCategoriesInfo.value.map((category) => ({
    ...category,
    name: t(`WORKFLOW.CATEGORIES.${category.name.toUpperCase()}.NAME`)
  }));
});

const updateCategories = (id: number) => {
  selectedCategoryIds.value = [id];
  emit("update:modelValue", selectedCategoryIds.value);
};

const isSelectedCategory = (id: number) => {
  return selectedCategoryIds.value.includes(id);
};

const selectAll = () => {
  selectedCategoryIds.value = [];
  emit("update:modelValue", []);
};
</script>
